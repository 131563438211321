import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Arjo from "./arjo/Arjo";
import AdminMode from "./component/AdminMode";
import Callback from "./auth/Callback";
import NotFound from "./component/NotFound";
import Hcs from "./hcs/Hcs";
import Logisnext from "./logisnext/Logisnext";
import OnBoardingFloorplan from "./onboarding/OnBoardingFloorplan";
import { AuthProvider } from "./auth/hcsauthprovider";

export default function Main() {
  return (
    <div className="notranslate">
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path="/onboarding/:lang/:formType"
              element={<OnBoardingFloorplan />}
            />
            <Route path="/onboarding/:lang" element={<OnBoardingFloorplan />} />
            {/*<Route path="/dev/onboarding/:lang" element={<OnBoarding />} />*/}
            <Route path="/" element={<Arjo />} />
            <Route path="/callback" element={<Callback />} />
            <Route exact path="/admin" element={<AdminMode />} />
            <Route path="/logisnext/:lang/:formType" element={<Logisnext />} />
            <Route path="/arjo/:lang/:formType" element={<Arjo />} />
            <Route path="/hcs/:lang/:formType" element={<Hcs />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}
