export function formatNumber(number, countryCode) {
  if (number === undefined) return "";
  let whole, decimal, remainder, result, increase;
  const thousandSeparator = countryCode === "uk" ? "," : ".";
  const decimalSeparator = countryCode === "uk" ? "." : ",";
  number = number.toString();
  if (number === "") return number;
  [whole, decimal] = number.split(".");
  if (decimal?.length >= 3 && decimal[2] >= 5) {
    increase =
      decimal[0] === "0"
        ? parseInt(decimal.substring(1, 2))
        : parseInt(decimal.substring(0, 2));
    increase += 1;
    increase = increase.toString();
    decimal = increase.length === 1 ? "0" + increase : increase;
  }
  decimal = decimal === undefined ? "00" : decimal.substring(0, 2);
  if (whole.length <= 3) return whole + decimalSeparator + decimal;
  remainder = whole.length % 3;
  result = remainder ? [whole.slice(0, remainder)] : [];

  for (let index = remainder; index <= whole.length - 3; index += 3) {
    result.push(whole.slice(index, index + 3));
  }
  return result.join(thousandSeparator) + decimalSeparator + decimal;
}

export function generateRandomContractNumber() {
  return Math.floor(Math.random() * 9000) + 1000;
}

export function getTodaysDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const date = `${year}-${month}-${day}`;
  return date;
}
export function splitAddress(address) {
  const parts = address.trim().split(" ");

  // Check if the last part is a number (house number)
  const lastPart = parts[parts.length - 1];
  const houseNumber = !isNaN(lastPart) ? parts.pop() : parts.shift();

  const street = parts.join(" ");

  return {
    street,
    houseNumber,
  };
}

export function dateToString(date) {
  if (!date) {
    return "";
  }
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() < 9 ? "0" : "") +
    (date.getMonth() + 1) +
    "-" +
    (date.getDate() < 10 ? "0" : "") +
    date.getDate()
  );
}

export function appendDictToFormData(formData, dict, formType, newDealer) {
  let all = [
    "name",
    "serial",
    "monthly",
    "totalMonthlyFee",
    "dealerName",
    "dealerEmail",
    "quantity",
    "contractLength",
    "repurchase",
    "deliveryDate",
    "customer",
    "currency",
    "AccessoriesQuantity",
  ];
  let leaseplus = [
    "socialSecurityNumber",
    "dateOfBirth",
    "customerName",
    "customerEmail",
    "customerPhone",
    "customerAddress",
    "customerZipCode",
    "customerCity",
    "customerNumber",
    "sellerName",
    "purchasePrice",
    "installation",
    "residualPrice",
    "accessory",
    "purchasePriceAccessory",
    "residualPriceAccessory",
    "customerFirstName",
    "customerLastName",
    "customerStreet",
    "customerHouseNumber",
    "apiError",
    "extraInfo",
  ];
  let flexPro = [
    "customerName",
    "address",
    "city",
    "zipCode",
    "companyNumber",
    "customerEmail",
    "nameSignatory",
    "emailSignatory",
    "phoneSignatory",
    "dateOfBirthCustomerSignatory",
    "socialSecurityNumberCustomerSignatory",
    "customerNumber",
    "sellerName",
    "price",
    "residual",
    "AccessoriesName",
    "AccessoriesPrice",
    "TotalAccessoriesPrice",
    "installation",
  ];
  let construction = [
    "customerName",
    "address",
    "city",
    "zipCode",
    "companyNumber",
    "customerEmail",
    "nameSignatory",
    "emailSignatory",
    "phoneSignatory",
    "dateOfBirthCustomerSignatory",
    "socialSecurityNumberCustomerSignatory",
    "price",
    "residual",
    "AccessoriesName",
    "AccessoriesPrice",
    "TotalAccessoriesPrice",
  ];
  let upcare = [
    "customerName",
    "address",
    "city",
    "zipCode",
    "companyNumber",
    "customerEmail",
    "nameSignatory",
    "emailSignatory",
    "phoneSignatory",
    "dateOfBirthCustomerSignatory",
    "socialSecurityNumberCustomerSignatory",
    "customerNumber",
    "sellerName",
    "purchasePrice",
    "installation",
    "residualPrice",
    "AccessoriesName",
    "AccessoriesPrice",
    "TotalAccessoriesPrice",
  ];
  let onboarding = [
    "newDealerName",
    "organizationNumber",
    "taxNumber",
    "husqvarnaNumber",
    "dealerAddress",
    "nd_zipCode",
    "nd_city",
    "invoiceEmail",
    "contactPerson",
    "emailContactPerson",
    "phoneContactPerson",
    "nameSignatoryNewDealer",
    "emailSignatoryNewDealer",
    "dateOfBirthSignatory",
    "socialSecurityNumberSignatory",
    "fcaNumber",
  ];
  let arrays = [
    "name",
    "serial",
    "purchasePrice",
    "installation",
    "price",
    "residualPrice",
    "residual",
    "deliveryDate",
    "nameSignatory",
    "emailSignatory",
    "phoneSignatory",
    "dateOfBirthCustomerSignatory",
    "socialSecurityNumberCustomerSignatory",
    "nameSignatoryNewDealer",
    "emailSignatoryNewDealer",
    "dateOfBirthSignatory",
    "socialSecurityNumberSignatory",
    "AccessoriesName",
    "AccessoriesPrice",
    "TotalAccessoriesPrice",
    "AccessoriesQuantity",
    "accessory",
    "purchasePriceAccessory",
    "residualPriceAccessory",
    "quantity",
  ];
  let standard = [
    "customerName",
    "companyNumber",
    "customerEmail",
    "nameSignatory",
    "emailSignatory",
    "phoneSignatory",
    "dateOfBirthCustomerSignatory",
    "socialSecurityNumberCustomerSignatory",
    "price",
    "residual",
    "AccessoriesName",
    "AccessoriesPrice",
    "TotalAccessoriesPrice",
  ];
  for (const [key, value] of Object.entries(dict)) {
    if (
      !(value instanceof Object) ||
      key === "accessory" ||
      key === "purchasePriceAccessory" ||
      key === "residualPriceAccessory"
    ) {
      if (
        all.includes(key) ||
        (formType === "flexpro" && flexPro.includes(key)) ||
        (formType === "construction" && construction.includes(key)) ||
        (formType === "leaseplus" && leaseplus.includes(key)) ||
        (formType === "upcare" && upcare.includes(key)) ||
        (formType === "standard" && standard.includes(key)) ||
        (newDealer && onboarding.includes(key))
      ) {
        if (arrays.includes(key)) {
          if (
            key === "accessory" ||
            key === "purchasePriceAccessory" ||
            key === "residualPriceAccessory"
          ) {
            if (formData[key] instanceof Object) {
              formData[key].push(value);
            } else {
              formData[key] = [value];
            }
          } else {
            if (formData[key] instanceof Object) {
              formData[key].push(value);
            } else {
              formData[key] = [value];
            }
          }
        } else {
          formData[key] = value;
        }
      }
    } else if (key === "serial") {
      if (formData.hasOwnProperty("serial")) {
        formData[key].push(value.length > 0 ? value[0] : "-");
      } else {
        formData[key] = [value.length > 0 ? value[0] : "-"];
      }
    } else if (value instanceof Date) {
      if (arrays.includes(key)) {
        if (formData[key] instanceof Object) {
          formData[key].push(dateToString(value));
        } else {
          formData[key] = [dateToString(value)];
        }
      } else {
        formData[key] = dateToString(value);
      }
    } else {
      if (
        key !== "labels" &&
        key !== "extraFiles" &&
        key !== "contractFile" &&
        key !== "creditFile"
      ) {
        appendDictToFormData(formData, value, formType, newDealer);
      }
    }
  }
  return formData;
}

export function customizeDataForOnboarding(data, countryCode) {
  const orderMapping = {
    general: [
      "newDealerName",
      "taxNumber",
      "organizationNumber",
      "husqvarnaNumber",
      "dealerAddress",
      "nd_zipCode",
      "nd_city",
      "invoiceEmail",
      "contactPerson",
      "emailContactPerson",
      "phoneContactPerson",
    ],
    uk: [
      "newDealerName",
      "taxNumber",
      "organizationNumber",
      "husqvarnaNumber",
      "fcaNumber",
      "dealerAddress",
      "nd_zipCode",
      "nd_city",
      "invoiceEmail",
      "contactPerson",
      "emailContactPerson",
      "phoneContactPerson",
    ],
    no: [
      "newDealerName",
      "organizationNumber",
      "husqvarnaNumber",
      "dealerAddress",
      "nd_zipCode",
      "nd_city",
      "invoiceEmail",
      "contactPerson",
      "emailContactPerson",
      "phoneContactPerson",
    ],
  };
  let order;
  if (countryCode === "uk") {
    order = orderMapping["uk"];
  } else if (countryCode === "no") {
    order = orderMapping["no"];
  } else {
    order = orderMapping["general"];
  }

  let rowData = order.map((key) => data[key]);

  const socialSecurityNumberCountries = ["se", "dk", "no", "fi"];
  Object.keys(data.newDealerSignatories).forEach((id) => {
    rowData.push(data.newDealerSignatories[id].nameSignatoryNewDealer);
    if (socialSecurityNumberCountries.includes(countryCode)) {
      rowData.push(data.newDealerSignatories[id].socialSecurityNumberSignatory);
    } else {
      let date = dateToString(
        data.newDealerSignatories[id].dateOfBirthSignatory
      );
      rowData.push(date);
    }
    rowData.push(data.newDealerSignatories[id].emailSignatoryNewDealer);
  });
  return rowData;
}
